import React from "react"
import { Container, Inner, Image, Title } from "./projects.styles"

export default function Projects({ data }) {
  return (
    <Container>
      {data.map((node) => (
        <Inner key={node.id} to={`/portfolio/${node.slug}`}>
          <Image
            image={node.representative.gatsbyImageData}
            alt={node.representative.title}
          />
          <Title>{node.title}</Title>
        </Inner>
      ))}
    </Container>
  )
}
