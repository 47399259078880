import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Projects from "../components/projects"

export const query = graphql`
  query Landing {
    site {
      siteMetadata {
        title
      }
    }

    allContentfulProject(sort: { fields: date, order: DESC }) {
      nodes {
        id
        slug
        title
        date
        representative {
          id
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
        }
      }
    }
  }
`

export default function Index({ data }) {
  const projects = get(data, "allContentfulProject.nodes")
  if (!projects) return null

  return (
    <Layout>
      <SEO title="Home" />
      <Projects data={projects} />
    </Layout>
  )
}
