import React from "react"
import { Container } from "./footer.styles"

export default () => {
  return (
    <Container>
      <span>Copyright © {new Date().getFullYear()}</span>
    </Container>
  )
}
