import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
`

export const Inner = styled(Link)`
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: 1200rem;
  margin-top: 2rem;
  padding: 2rem 2rem 4rem;
  transition: background-color 300ms ease 30ms;
  border-radius: 2px;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  &:nth-child(1n) {
    flex: 1 1 100%;
  }

  &:nth-child(2n) {
    flex: 1 1 50%;

    ${media.lessThan("medium")`
      flex: 1 1 100%;
    `}
  }

  &:nth-child(3n) {
    flex: 1 1 50%;

    ${media.lessThan("medium")`
      flex: 1 1 100%;
    `}
  }
`

export const Image = styled(GatsbyImage)``

export const Title = styled.h2`
  margin: 1rem 0 0 0;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1.4rem;
`
