import React from "react"
import { Container, AboutLink } from "./header.styles"

import Logo from "./logo"

export default () => {
  return (
    <Container>
      <Logo />
      <AboutLink to="/about" activeClassName="active">
        About
      </AboutLink>
    </Container>
  )
}
