import styled from "styled-components"
import media from "styled-media-query"
import { GatsbyImage } from "gatsby-plugin-image"

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;

  ${media.greaterThan("huge")`
    width: 60%;
    margin: 0 auto;
  `}
`

export const Image = styled(GatsbyImage)`
  flex: 1 1 100%;
  max-width: 80rem;
  margin: 4rem 2rem;

  /* &:nth-child(1) {
    flex: 0 1 100% !important;
  }

  &:nth-child(1n) {
    flex: 0 1 60%;
  }

  &:nth-child(2n) {
    flex: 0 1 60%;
  }

  &:nth-child(3n) {
    flex: 0 1 80%;
  } */
`
