import styled from "styled-components"

export const Container = styled.footer`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0%;
  padding: 2rem;
  font-size: 1.2rem;
  text-align: center;
  text-transform: lowercase;
  background: #fff;

  span {
    color: rgba(0, 0, 0, 0.45);

    /* text-shadow: 0.2px 0.2px 0 rgba(0, 0, 0, 0.5); */
    user-select: none;
  }
`
