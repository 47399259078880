import React from "react"
import { ThemeProvider } from "styled-components"
import theme from "../theme"
import { GlobalStyle, Container, Main } from "./layout.styles"

import Header from "./header"
import Footer from "./footer"

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <React.Fragment>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <Container>
            <Header />
            <Main>{children}</Main>
            <Footer />
          </Container>
        </ThemeProvider>
      </React.Fragment>
    )
  }
}

export default Layout
