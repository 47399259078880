import styled, { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import media from "styled-media-query"

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  html, body {
    color: #000;
    font-size: 10px;
    font-family: 'Helvetica', sans-serif;
    line-height: 2.4rem;
    background: #fcfcfc;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    text-decoration: none;
  }
  ::selection {
    color: #fff;
    background: #000;
  }
`

export const Container = styled.div``

export const Main = styled.div`
  position: relative;
  z-index: 10;
  margin: 18rem 0 6rem;
  padding: 2rem 10vw;
  background: #fcfcfc;

  ${media.lessThan("large")`
    padding: 2rem;
  `}

  ${media.lessThan("medium")`
  margin: 14rem 0 6rem;
    padding: 0;
  `}

  p {
    margin: 0 0 2.4rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
`
