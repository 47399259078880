import styled from "styled-components"
import { Link } from "gatsby"
import LogoImg from "../images/logo.png"

export const Container = styled(Link)``
export const Title = styled.h1`
  width: 12rem;
  height: 3.5rem;
  margin: 0;
  padding: 0;
  text-indent: -9999px;
  background-image: url(${LogoImg});
  background-repeat: no-repeat;
  background-size: 100% auto;
`
