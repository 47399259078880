import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Container, Title, Text } from "./frontmatter.styles"

export default function Frontmatter({ data }) {
  return (
    <Container>
      <Title>{data.title}</Title>
      {data.text && <Text>{renderRichText(data.text)}</Text>}
    </Container>
  )
}
