import React from "react"
import { Container, Image } from "./assets.styles"

export default function Assets({ data }) {
  return (
    <Container>
      {data.map((node) => (
        <Image key={node.id} image={node.gatsbyImageData} alt={node.title} />
      ))}
    </Container>
  )
}
