import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  margin: 4rem 2rem;
  max-width: 60rem;

  ${media.greaterThan("medium")`
    margin: 4rem auto;
  `}

  ${media.greaterThan("huge")`
    position: fixed;
    left: 2rem;
    width: 20%;
    z-index: 100;
  `}
`

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 1.4rem;
  margin: 0 0 1.2rem;
`

export const Text = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.4rem;
  line-height: 2.4rem;

  a {
    color: inherit;
    text-decoration: underline;
  }
`
