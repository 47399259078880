const BASE_FONT_SIZE = 1.8 // ems
const SCALE_RATIO = 1.25
const GLOBAL_LINE_HEIGHT = 2.8 // rems

// const breakpoints = [`32em`, `48em`, `64em`, `80em`]
const breakpoints = [`32em`, `67.5em`, `75em`]
breakpoints.phone = breakpoints[0]
breakpoints.tablet = breakpoints[1]
breakpoints.desktop = breakpoints[2]
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const lineHeights = {
  mobile: {
    0: { 0: `0rem`, 5: `1.2rem` },
    1: { 0: `2.4rem`, 5: `3.6rem` },
    2: { 0: `4.8rem`, 5: `6.0rem` },
    3: { 0: `7.2rem`, 5: `8.4rem` },
    4: { 0: `9.7rem`, 5: `10.8rem` },
    5: { 0: `12rem`, 5: `13.2rem` },
    6: { 0: `14.4rem`, 5: `15.6rem` },
    7: { 0: `16.8rem`, 5: `18rem` },
    8: { 0: `19.2rem`, 5: `20.4rem` },
    9: { 0: `21.6rem`, 5: `22.8rem` },
    10: { 0: `24rem`, 5: `25.2rem` },
  },
  desktop: {
    0: { 0: `0rem`, 5: `1.4rem` },
    1: { 0: `2.8rem`, 5: `4.2rem` },
    2: { 0: `5.6rem`, 5: `7.0rem` },
    3: { 0: `8.4rem`, 5: `9.8rem` },
    4: { 0: `11.2rem`, 5: `12.6rem` },
    5: { 0: `14rem`, 5: `15.4rem` },
    6: { 0: `16.8rem`, 5: `18.2rem` },
    7: { 0: `19.6rem`, 5: `21rem` },
    8: { 0: `22.4rem`, 5: `23.8rem` },
    9: { 0: `25.2rem`, 5: `26.6rem` },
    10: { 0: `28rem`, 5: `29.4rem` },
  },
}

const x = [
  "0rem",
  "0.8rem",
  "1.6rem",
  "2.4rem",
  "3.2rem",
  "4.0rem",
  "4.8rem",
  "5.6rem",
  "6.4rem",
  "7.2rem",
  "8.0rem",
  "8.8rem",
  "9.6rem",
  "10.4rem",
  "11.2rem",
  "12.0rem",
  "12.8rem",
  "13.6rem",
]

export default {
  breakpoints,
  space: {
    y: lineHeights,
    x,
  },
  fontSizes: {
    mobile: {
      "-3": `0.901rem`,
      "-2": `1.2rem`,
      "-1": `1.6rem`,
      0: `2.133rem`,
      1: `2.843rem`,
      2: `3.79rem`,
      3: `5.52rem`,
      4: `6.735rem`,
      5: `8.977rem`,
      6: `8.977rem`,
    },
    desktop: {
      "-3": `10.13rem`,
      "-2": `1.35rem`,
      "-1": `1.6rem`,
      0: `1.8rem`,
      1: `2.4rem`,
      2: `3.2rem`,
      3: `4.2rem`,
      4: `5.6rem`,
      5: `7.6rem`,
      6: `10rem`,
    },
  },
  fontWeights: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: lineHeights,
  letterSpacings: {},
  fonts: {
    serif: `"Times New Roman", serif`,
    sansSerif: `"Simplon Norm", sans-serif`,
    mono: `"Simplon Mono", sans-serif`,
  },
  border: [0],
  radii: [],
  sizes: { heights: lineHeights, widths: x },
  colors: {
    text: "hsl(0,0%,20%)",
    primary: "#1E1E1D",
    secondary: "#fbe6bf",
    error: "red",
    black: [
      "hsl(0,0%,0%)",
      "hsl(0,0%,10%)",
      "hsl(0,0%,20%)",
      "hsl(0,0%,30%)",
      "hsl(0,0%,40%)",
      "hsl(0,0%,50%)",
      "hsl(0,0%,60%)",
      "hsl(0,0%,70%)",
      "hsl(0,0%,80%)",
      "hsl(0,0%,90%)",
      "hsl(0,0%,100%)",
    ],
    white: ["#fff"],
  },
}
