import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 35rem;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);
`

export const query = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default function About({ data }) {
  return (
    <Layout>
      <SEO title="About" />
      <Container>
        <p>
          We are developing interactive user interfaces and graphics with focus
          on simplicity and branding.
        </p>
        {/* Contact: Email and LinkedIn */}
      </Container>
    </Layout>
  )
}
