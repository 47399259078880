import React from "react"
import { Container, Title } from "./logo.styles"

export const Logo = () => {
  return (
    <Container to="/">
      <Title>4com</Title>
    </Container>
  )
}

export default Logo
