import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Frontmatter from "../components/frontmatter"
import Assets from "../components/assets"

export const ContentfulProject = graphql`
  query ProjectQuery($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      id
      title
      text {
        raw
      }
      assets {
        id
        title
        gatsbyImageData(
          layout: CONSTRAINED
          width: 800
          backgroundColor: "#FFFFFF"
          placeholder: DOMINANT_COLOR
        )
      }
    }
  }
`

export default function Project({ data }) {
  const project = get(data, "contentfulProject")

  return (
    <Layout>
      <SEO title={project.title} />
      <Frontmatter data={project} />
      <Assets data={project.assets} />
    </Layout>
  )
}
