import styled from "styled-components"
import { Link } from "gatsby"

export const Container = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 4rem;
`

export const AboutLink = styled(Link)`
  position: absolute;
  right: 2rem;
  padding: 1rem 2rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.4rem;

  &:hover,
  &.active {
    text-decoration: underline;
  }
`
